import type { IconName } from '../ui/Icon';
import { Icon } from '../ui/Icon'

type IconTextBlockProps = {
  title: string;
  content?: string | JSX.Element;
  iconName?: IconName;
}

export const IconTextBlock = ( { title, content, iconName }: IconTextBlockProps ) => {
  return (
    <div className={ 'flex flex-col gap-2.5' }>
      <div className={ 'flex flex-row items-center font-semibold gap-2.5' }>
        { iconName &&
          <Icon
            name={ iconName }
            className={ 'icon-size-6' } />
        }
        <p>
          {title}
        </p>
      </div>
      { content &&
        <p className={ 'text-sm leading-6 font-light' }>
          {content}
        </p>
      }
    </div>
  )
}